import React from 'react'

import flowerEmptyBorder from '../assets/flowerEmptyBorder.png'
import flowerFullBorder from '../assets/flowerFullBorder.png'
import leafEmptyBorder from '../assets/leafEmptyBorder.png'
import leafFullBorder from '../assets/leafFullBorder.png'
import flowerEmptyEmpty from '../assets/flowerEmptyEmpty.png'
import flowerFullEmpty from '../assets/flowerFullEmpty.png'
import leafEmptyEmpty from '../assets/leafEmptyEmpty.png'
import leafFullEmpty from '../assets/leafFullEmpty.png'

type TProps = {
    isHidden?: boolean;
    isDisabled?: boolean;
    filled: boolean;
	symbol: 'flower' | 'leaf';
	border: boolean;
    onClick?: () => void;
    hotkey?: string
}

const Option: React.FC<TProps> = ({filled, symbol, border, isDisabled, isHidden, onClick, hotkey}) => {

    React.useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if (e.code === hotkey && onClick && !isHidden) {
                onClick()
            }
		}

		window.addEventListener('keydown', keyHandler)

		return () => window.removeEventListener('keydown', keyHandler)
    }, [onClick, isHidden, hotkey])

    const getIcon = () => {
        if (symbol === 'flower' && filled && border) {
            return flowerFullBorder
        }
        if (symbol === 'flower' && !filled && border) {
            return flowerEmptyBorder
        }
        if (symbol === 'flower' && filled && !border) {
            return flowerFullEmpty
        }
        if (symbol === 'flower' && !filled && !border) {
            return flowerEmptyEmpty
        }

        if (symbol === 'leaf' && filled && border) {
            return leafFullBorder
        }
        if (symbol === 'leaf' && !filled && border) {
            return leafEmptyBorder
        }
        if (symbol === 'leaf' && filled && !border) {
            return leafFullEmpty
        }
        if (symbol === 'leaf' && !filled && !border) {
            return leafEmptyEmpty
        }
        return ''
    }

    const getOpacity = () => {
        if (isHidden) {
            return 0
        }
        if (isDisabled) {
            return 0.2
        }
        return 1
    }
    
    return (
        <div
            className='option'
            onClick={isHidden ? undefined : onClick}
            style={{
                opacity: getOpacity(),
                cursor: isHidden ? undefined : 'pointer',
                margin: '0 10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                fontSize: '48px',
            }}
        >
            <img src={getIcon()} alt='' style={{ width: '100%', height: '100%' }} />
        </div>
    )
}

export default Option