import React from 'react';
import Option from './components/Option'

type TOption = {
	filled: boolean;
	symbol: 'flower' | 'leaf';
	border: boolean;
	key: string;
}

const OPTIONS: TOption[] = [
	{ symbol: 'flower', filled: false, border: false, key: 'Digit1' },
	{ symbol: 'flower', filled: true, border: false, key: 'Digit2' },
	{ symbol: 'flower', filled: false, border: true, key: 'Digit3' },
	{ symbol: 'flower', filled: true, border: true, key: 'Digit4' },
	{ symbol: 'leaf', filled: false, border: false, key: 'Digit5' },
	{ symbol: 'leaf', filled: true, border: false, key: 'Digit6' },
	{ symbol: 'leaf', filled: false, border: true, key: 'Digit7' },
	{ symbol: 'leaf', filled: true, border: true, key: 'Digit8' },
]

const compareOption = (option1: TOption, option2: TOption) => {
	return option1.filled === option2.filled && option1.symbol === option2.symbol && option1.border === option2.border;
}

const App = () => {
	const [picked, setPicked] = React.useState<TOption[]>([]);

	React.useEffect(() => {
			const keyHandler = (e: KeyboardEvent) => {
				if (e.code === 'Space') {
					setPicked([])
				}
			}
	
			window.addEventListener('keydown', keyHandler)
	
			return () => window.removeEventListener('keydown', keyHandler)
		}, [])

	const getSolution = (): TOption | null => {
		if (picked.length !== 4) {
			return null
		}

		let solution = null

		picked.forEach((pickedOption) => {
			const isSymbolSolution = picked.every((option) => compareOption(pickedOption, option) || option.symbol !== pickedOption.symbol);
			const isFilledSolution = picked.every((option) => compareOption(pickedOption, option) || option.filled !== pickedOption.filled);
			const isBorderSolution = picked.every((option) => compareOption(pickedOption, option) || option.border !== pickedOption.border);

			if (isSymbolSolution || isFilledSolution || isBorderSolution) {
				solution = pickedOption
			}
		})
		
		return solution
	}
	const solution = getSolution()

	return (
		<div className="App" style={{ position: 'fixed', color: 'white', padding: '10px', borderRadius: '12px', background: 'rgba(0, 0, 0, 0.8)', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
			<div className='result' style={{ height: '127px', display: 'flex', justifyContent: 'center', margin: '20px 0', marginTop: '40px' }}>
				{solution ? (
					<Option
						filled={solution.filled}
						symbol={solution.symbol}
						border={solution.border}
					/>
				) : (
					<div style={{ fontSize: '24px' }}>No solution</div>
				)}
			</div>

			<hr />

			<div className='picked' style={{ height: '127px', display: 'flex', justifyContent: 'center', margin: '20px 0', marginTop: '40px' }}>
				{picked.map((option, index) => (
					<div key={index}>
						<Option
							onClick={() => {
								setPicked(picked.filter((pickedOption) => !compareOption(pickedOption, option)));
							}}
							filled={option.filled}
							symbol={option.symbol}
							border={option.border}
							hotkey={option.key}
						/>
					</div>
				))}
			</div>

			<div className='options' style={{ display: 'flex', margin: '20px 0', marginTop: '40px' }}>
				{OPTIONS.map((option, index) => {
					const isSelected = picked.some((pickedOption) => compareOption(pickedOption, option));
					return (
						<div key={index}>
							<Option
								isHidden={!isSelected && picked.length >= 4}
								isDisabled={isSelected || picked.length >= 4}
								onClick={() => {
									if (picked.length >= 4 && !isSelected) {
										return
									}

									if (isSelected) {
										setPicked(picked.filter((pickedOption) => !compareOption(pickedOption, option)));
									} else {
										setPicked([...picked, option]);
									}
								}}
								filled={option.filled}
								symbol={option.symbol}
								border={option.border}
								hotkey={option.key}
							/>
						</div>
					)
				})}
			</div>

			<div className='reset' style={{ display: 'flex', justifyContent: 'center' }}>
				<button className='reset-button' style={{ background: 'cyan', cursor: 'pointer', width: '120px', height: '30px', borderRadius: '12px' }} onClick={() => setPicked([])}>RESET</button>
			</div>
		</div>
	);
}

export default App;
